import React from 'react'

import YoutubeVideo from '../YoutubeVideo'

const episodes = [
  {
    title: 'Duell der Giganten',
    date: '17.01.2015',
    description:
      'Der Debütauftritt des Stadlwirts! Zusammen mit Haubenkoch Oliver Hoffinger und Jeannine Mik werden großartige Speisen in der Puls4-Küche herbeigezaubert. Knusprige Frühlingsrollen, cremige Käsespätzle und köstliche Cordon Bleus. So gut schmeckt Österreich.',
    youtubeVideoId: 'pfvDBtVNNjg',
    receipts: [
      {
        title: "Walter Lutz' Spätzlehauf'n",
        href:
          'http://www.puls4.com/kochmitoliver/rezepte/Walter-Lutz-Spaetzlehauf-n-54486',
      },
      {
        title: 'Cordon Bleu',
        href:
          'http://www.puls4.com/kochmitoliver/rezepte/Riesen-Cordon-Bleu-76064',
      },
    ],
  },
  {
    title: 'Die Revanche',
    date: '11.04.2015',
    description:
      'Da das Duell zwischen Oliver Hoffinger und Walter Lutz im Januar unentschieden ausging, kommt nun die Revanche der kochenden Kontrahenten! Gekocht werden diesmal ein gebackener Camembert mit Himbeer-Chili-Marmelade und Langos mit Sauerrahm und Käse.',
    youtubeVideoId: 'dQnNVR6WqNM',
  },
  {
    title: 'Exotischer Rollentausch',
    date: '09.01.2016',
    description:
      'Und wieder finden sich der Stadlwirt aus Nauders und Haubenkoch Oliver Hoffinger in der Küche wieder. Diesmal wird es exotisch: Spanische Churros mit Schokoladensoße und Stadlwirtkiachln als Dessert, Schottische Eier und ein Riesenkalbsschnitzel mit französischen Duchesse-Kartofelln als Hauptspeise. Die Herausforderung: Die beiden Köche müssen jeweils die Gerichte des anderen kochen',
    youtubeVideoId: 'a_FywPshzq8',
  },
  {
    title: 'Nauders Special',
    date: '17.07.2016',
    description:
      'Kochen auf 2000m Seehöhe! Nachdem der Stadlwirt nun schon des Öfteren bei Oliver in Wien zu Gast war, entschieden sie sich eine Spezialfolge in Nauders aufzunehmen. Die wohl schönste Küche bereit diesmal als Hauptspeise einen gebratenen Spargel auf Orangenrahm mit zartem Tiroler Speck und das größte Cordon Bleu Österreichs zu. Als Nachspeise gibt es köstliche Apfelkircherln mit Italienischem Gelato, Schweizer Schokolade und Tiroler Äpfeln.',
    youtubeVideoId: 'FpYhFwVoDYg',
  },
  {
    title: 'Weihnachtliches Kochen',
    date: '18.12.2016',
    description:
      'Es weihnachtet in der Küche! Heute zaubern die beiden Meisterköche zwei besondere Gerichte herbei. Zum einen eine neapolitanische Pizza Fritta, eine frittierte Variante der allbekannten Pizza aus Italien. Zum anderen ein Gericht das seinen Ursprung auf der anderen Seite des Atlantiks hat; ein krosses und besonders würziges Südstaaten-Backhendl aus den Vereinigten Staaten.',
    youtubeVideoId: '7auallj8BJY',
  },
  {
    title: 'Internationale Küche (in Nauders)',
    date: '23.7.2017',
    description:
      'Oliver is(s)t international! Walter und Oliver "The Hoff" Hoffinger zaubern Käsespätzle mit Spargelsalat, während sie einen herrlichen Ausblick ins Dreiländereck Schweiz-Italien-Österreich genießen.',
    youtubeVideoId: 'UE67mcykITU',
  },
  {
    title: 'Tiroler Küche (in Nauders)',
    date: '22.10.2017',
    description:
      'Im wunderschönen Nauders haben sich die zwei Spitzenköche mal wieder etwas besonderes einfallen lassen. Das traditionelle Gericht "Schlutzkrapfen" wird auf Nord- und Südtirolerische Weise zubereitet.',
    youtubeVideoId: 'rJgZJQE2ZdQ',
  },
  {
    title: 'Nahostküche',
    date: '17.12.2017',
    description:
      'Walter und Oliver kochen das israelische Nationalgericht; Falavel. Dazu gibt es Humus und eine köstliche Tomaten-Gurken-Salsa. Guten Appetit!',
    youtubeVideoId: '5kmOZHjpsOY',
  },
  {
    title: 'Die Burger Kings',
    date: '25.03.2018',
    description:
      'Alles hat ein Ende, nur das Schnitzel hat zwei. Auf ein letztes Mal begeben sich die zwei Kochgiganten nach Kufstein, um den ultimativsten aller Burger zuzubereiten!',
    youtubeVideoId: 'o0lBWFEhO3E',
  },
  {
    title: 'Wennser Spezialitäten',
    date: '12.08.2018',
    description:
      'Freudiges Wiedersehen bei Koch mit Oliver! Dreierlei vom Knödel, Krautroulade und weitere Köstlichkeiten werden im wunderschönen Wenns zubereitet!',
    youtubeVideoId: 'U0b213mvrek',
  },
  {
    title: 'Weihnachtsgröstl & Dukatenschnitzel',
    date: '09.12.2018',
    description:
      'Wieder mal treffen sich die zwei Meisterköche, diesmal um ein weihnachtliches Gröstl zu kochen. Oliver macht währenddessen ein köstliches Dukatenschnitzel.',
    youtubeVideoId: 'kcIfQYj37iA',
  },
].reverse() as {
  title: string
  date: string
  description: string
  youtubeVideoId: string
  receipts?: {
    title: string
    href: string
  }[]
}[]

const KochMitOliver = () => {
  return (
    <section className="py-10">
      <div className="container text-center">
        <h2 className="text-2xl">TV Show &quot;Koch mit Oliver&quot;</h2>
        <hr className="my-2 mx-auto" />
        <p className="max-w-2xl mx-auto mb-5">
          Der Stadlwirt war schon des Öfteren bei dem bekannten Fernsehkoch
          Oliver Hoffinger in dessen Show &quot;Koch mit Oliver&quot; zu Gast.
          Genießen sie die freundliche Stimmung der Sendungen oder kochen Sie
          die Rezepte selbst nach!
        </p>
        {episodes.map(episode => {
          const {
            title,
            date,
            description,
            youtubeVideoId,
            receipts = [],
          } = episode

          return (
            <div
              key={title}
              id={title}
              className="text-left bg-white px-4 pt-3 rounded mb-5"
            >
              <span className="text-xs text-gray-700">{date}</span>
              <h3 className="text-xl font-semibold">{title}</h3>
              <hr className="my-2" />
              <p className="max-w-2xl mb-3">{description}</p>
              {receipts.length > 0 && (
                <div className="mb-3">
                  <h4 className="text-lg">Rezepte zum Nachkochen</h4>
                  <ul>
                    {receipts.map(receipt => {
                      const { title, href } = receipt

                      return (
                        <li key={href}>
                          <a
                            className="golden"
                            href={href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {title}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
              <YoutubeVideo
                className="max-w-3xl pb-1"
                videoId={youtubeVideoId}
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default KochMitOliver
