import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../components/SEO'
import HeroHeader from '../components/HeroHeader'
import KochMitOliver from '../components/Press/KochMitOliver'

const title = 'Presse | Stadlwirt'

const PressPage = () => {
  const { fluidBackgroundImage } = useStaticQuery(
    graphql`
      query {
        fluidBackgroundImage: file(
          relativePath: { eq: "walter-und-oliver_original.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  )

  return (
    <>
      <SEO title={title} />
      <HeroHeader
        title="Presse"
        staticBackgroundImage="/uploads/walter-und-oliver.jpg"
        fluidBackgroundImage={fluidBackgroundImage}
      />
      <KochMitOliver />
    </>
  )
}

export default PressPage
